import React, { Component } from "react"
import { graphql } from "gatsby"

class Impressum extends Component {


    render() {
        return (
            <div className="main-content">
                {this.props.data.allMarkdownRemark.edges.map(({ node }) => {
                    return <div key={node.id}>
                        <h2 dangerouslySetInnerHTML={{ __html: node.frontmatter.title }} />
                        <span dangerouslySetInnerHTML={{ __html: node.html }} />
                    </div>
                })}
            </div>

        )
    }
}

export default Impressum

export const pageQuery = graphql`
    query impressumQuery {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/impressum/"}}) {
        edges {
          node {
            id
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
`
